<template>
  <div>
    <section
      v-if="layoutInternalCreated"
      id="about"
      class="section-content content-text"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7">
            <h2>Aplicação de Insulfilm em Janelas</h2>

            <p>
              Devido a incidência do sol, não vale a pena perder o panorama
              emocionante dos ambientes onde possui janelas, vidros,
              <router-link :to="{ name: 'insulfilmServicosSacadas' }"
                >sacadas</router-link
              >, divisórias.
            </p>

            <p>
              Uma das soluções é aplicar uma boa película de insulfilm em
              janelas. Além de tornar espetacular a aparência de áreas onde
              possui janelas, os problemas causados pela visibilidade,
              temperatura e luz ultravioleta desaparecerão!
            </p>

            <p>
              O insulfim também pode ser usado para proteção, decoração e
              privacidade dos vidros em geral.
            </p>

            <h3>Benefícios do insulfilm para janela</h3>

            <ul class="list-disc">
              <li>Proteção solar e redução de calor</li>
              <li>Aumento da resistência dos vidros</li>
              <li>Proteção de objetos do sol</li>
              <li>Economia com o ar condicionado</li>
              <li>Modernização de fachadas</li>
              <li>Facilidade de limpeza</li>
            </ul>

            <h3>Insulfilm de proteção solar para janela</h3>
            <p>
              O
              <router-link
                :to="{ name: 'insulfilmProtecaoSolarJanela' }"
                title="insulfilm de proteção solar para janela"
                >insulfilm de proteção solar para janela</router-link
              >, reúne características fundamentais para a perfeita climatização
              de ambientes, com ganhos expressivos em economia, privacidade e
              conforto.
            </p>
            <div @click="index = 0" class="page-photo-container">
              <Photo
                :src="photos[0].thumb"
                :title="photos[0].title"
                :description="photos[0].description"
                :idLocation="photos[0].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>Insulfilm de segurança para janela</h3>

            <p>
              O
              <router-link
                :to="{ name: 'insulfilmSegurancaJanela' }"
                title="insulfilm de segurança para janela"
                >insulfilm de segurança para janela</router-link
              >
              torna o seu vidro mais resistente a danos e mais forte, evita
              danos e acidentes familiares e protege os seus bens e entes
              queridos.
            </p>

            <div @click="index = 1" class="page-photo-container">
              <Photo
                :src="photos[1].thumb"
                :title="photos[1].title"
                :description="photos[1].description"
                :idLocation="photos[1].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>Insulfilm decorativo para janela</h3>

            <p>
              Os
              <router-link
                :to="{ name: 'insulfilmDecorativoJanela' }"
                title="insulfilmes decorativos para janela"
                >insulfilmes decorativos para janela</router-link
              >
              dão privacidade ao local, proporcionam a sensação de conforto,
              segurança, e inovam os ambientes.
            </p>

            <div @click="index = 2" class="page-photo-container">
              <Photo
                :src="photos[2].thumb"
                :title="photos[2].title"
                :description="photos[2].description"
                :idLocation="photos[2].idLocation"
                :openLightBox="false"
              />
            </div>

            <h3>Insulfilm de privacidade para janela</h3>

            <p>
              O
              <router-link
                :to="{ name: 'insulfilmPrivacidadeJanela' }"
                title="insulfilm de privacidade para janela"
                >insulfilm de privacidade para janela</router-link
              >
              é uma solução simples para fornecer privacidade, segurança e
              beleza.
            </p>

            <div @click="index = 3" class="page-photo-container">
              <Photo
                :src="photos[3].thumb"
                :title="photos[3].title"
                :description="photos[3].description"
                :idLocation="photos[3].idLocation"
                :openLightBox="false"
              />
            </div>
          </div>
          <!-- col end -->

          <div class="col-lg-5 section-content-illustration">
            <QuotationCall position="float" />
          </div>
          <!-- col end -->
        </div>
        <!-- row end -->
      </div>
    </section>
  </div>
</template>

<script>
import LayoutInternal from "@/layouts/LayoutInternal.vue";
import { photoExtention } from "@/mixin/photo-extention.js";
import { layoutInternalCreated } from "@/mixin/layout-internal-created-mixin.js";

const QuotationCall = () =>
  import(
    /* webpackChunkName: "QuotationCall" */ "@/components/cotacao/quotation-call.vue"
  );
const Photo = () =>
  import(/* webpackChunkName: "Photo" */ "@/components/photo/photo.vue");

export default {
  mixins: [photoExtention, layoutInternalCreated],

  components: {
    QuotationCall,
    Photo,
  },

  data() {
    return {
      layoutLoad: false,
      index: null,
      photos: [
        //refletivo
        {
          description:
            "Película prata instalada nas janelas da TAM para fornecer conforto térmico",
          idLocation: 1,
          src: "/images/fotos/tam/pelicula-janela-prata-tam-16",
          thumb: "/images/fotos/tam/small/pelicula-janela-prata-tam-16",
          title: "Película para Janela",
        },

        //segurança
        {
          description:
            "Película de segurança usado para evitar que o vidro se quebre",
          idLocation: 1,
          src: "/images/fotos/seguranca/pelicula-seguranca-vidros",
          thumb: "/images/fotos/seguranca/pelicula-seguranca-vidros",
          title: "Película para Janela",
        },
        //decorativo
        {
          description:
            "Aplicação de película Mini Blind para decorar as divisórias de vidro",
          idLocation: 6,
          src: "/images/fotos/condominio-mini-blind/pelicula-decoracao-mini-blind-3",
          thumb:
            "/images/fotos/condominio-mini-blind/small/pelicula-decoracao-mini-blind-3",
          title: "Película para divisória",
        },

        {
          description:
            "Insulfilm branco jateado aplicado na janela para fornecer privacidade ao local",
          idLocation: 1,
          src: "/images/fotos/condominio-branco-jateado/pelicula-insulfilm-branco-jateado-condominio-7",
          thumb:
            "/images/fotos/condominio-branco-jateado/small/pelicula-insulfilm-branco-jateado-condominio-7",
          title: "Película para Janela",
        },
      ],
    };
  },

  mounted: function () {
    //console.log(this.photos)
    // console.log('mounted')
  },

  created() {
    this.$emit(`update:layout`, LayoutInternal);
    this.$emit(`update:title`, "Insulfilm para Janela");
    this.$emit(
      `update:introduction`,
      "Instalamos insulfilm para janela em comércios, sacadas, escritórios, apartamentos, portarias e na arquitetura em geral"
    );
    this.$emit(`update:bgHeader`, "black");
  },
};
</script>

<style lang="scss" scoped>
</style>